.card-form {
  box-shadow: 2px 1px 11px -2px rgba(135,133,133,0.58);
  -webkit-box-shadow: 2px 1px 11px -2px rgba(135,133,133,0.58);
  -moz-box-shadow: 2px 1px 11px -2px rgba(135,133,133,0.58);
    border-radius: 10px;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #fff;
}

.container .form {
  margin-top: 30px;
}
.form .input-box {
  width: 100%;
  margin-top: 20px;
}
.input-box label {
  color: #333;
}
.form :where(.input-box input, .select-box) {
  position: relative;
  height: 40px;
  width: 100%;
  outline: none;
  font-size: 0.8rem;
  color: #707070;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 0 15px;
}
.input-box input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.form .column {
  display: flex;
  column-gap: 15px;
}
.form .gender-box {
  margin-top: 20px;
}
.gender-box h3 {
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
}
.form :where(.gender-option, .gender) {
  display: flex;
  align-items: center;
  column-gap: 50px;
  flex-wrap: wrap;
}
.form .gender {
  column-gap: 5px;
}
.gender input {
  accent-color: #859A5B;
}
.form :where(.gender input, .gender label) {
  cursor: pointer;
}
.gender label {
  color: #707070;
}
.address :where(input, .select-box) {
  margin-top: 15px;
}
.select-box select {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  color: #707070;
  font-size: 0.8rem;
}
.btn-submit {
  height: 50px;
  width: 300px;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #859A5B;
  border-radius: 10px;
}


.button-upload button{
  margin-top: 5px;
  height: 40px;
  width: 100%;
  color: #6d6bed;
  border : 1px solid #6d6bed !important;
  font-size:0.9rem;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background: white;
  border-radius: 10px;
}



.file {
  position: relative;
  overflow: hidden;
}
.file > input {
  position: absolute;
  font-size: 50px;
  /* opacity: 0; */
  right: 0;
  top: 0;
  cursor: pointer;

}

.component {
  margin: 0 auto;
  max-width: 920px;
  width: 100%;
  background-color: #ffffff;
  background-image: url(../../images/bg-pattern-desktop.svg);
  background-position: -565px -295px;
  background-repeat: no-repeat;
  box-shadow: 0px 30px 30px -20px rgba(53, 18, 122, 0.497159);
  border-radius: 23px;
  display: flex;
}

.faq__desc .show-description {
  height: 2rem;
  margin-bottom: 15px;
}

/* Arrows */

/* closed */
.faq__question-button::after {
  transition: transform 300ms ease-out;
}

/* open */
.font-weight-bold::after {
  transform: rotate(720deg);
}


/* CSS Variables */
:root {
  --very-dark-desaturated-blue: hsl(238, 29%, 16%);
  --soft-red: hsl(91, 91%, 30%);
  /* Adjusted to Olive Green Theme */
  --soft-violet: hsl(90, 50%, 60%);
  --soft-blue: hsl(90, 50%, 65%);
  --very-dark-grayish-blue: hsl(90, 15%, 20%);
  --dark-grayish-blue: hsl(90, 15%, 35%);
  --light-grayish-blue: hsl(90, 15%, 80%); 
}

/* FAQ Description */
.faq__desc.show-description {
  height: 11rem;
  margin-bottom: 15px;
  width: 29rem;
}

/* Arrows */
/* Closed */
.faq__question-button::after {
  transition: transform 300ms ease-out;
}

/* Open */
.font-weight-bold::after {
  transform: rotate(720deg);
}

/* Attribution */
.attribution {
  font-size: 11px;
  text-align: center;
  color: var(--light-grayish-blue);
  padding-top: 10px;
}

.attribution a {
  color: var(--soft-violet);
}

/* Component */
.component {
  margin: 20px;
  /* max-width: 100px; */
  width: 100%;
  background-color: #ffffff;
  background-image: url(../../images/bg-pattern-desktop.svg);
  background-position: -565px -295px;
  background-repeat: no-repeat;
  box-shadow: 0px 30px 30px -20px rgba(226, 213, 251, 0.497);
  border-radius: 23px;
  display: flex;
}

/* FAQ */
.faq {
  width: 100%;
  padding-top: 65px;
  padding-bottom: 80px;
}

.faq__title {
  margin: 0;
  padding-bottom: 25px;
}

.faq__question {
  max-width: 350px;
  width: 100%;
  border-bottom: 1px solid var(--light-grayish-blue);
}

.faq__question-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  padding: 0px;
  cursor: pointer;
  padding-top: 18px;
  padding-bottom: 18px;
}

.faq__question-button::after {
  content: url(../../images/icon-arrow-down.svg);
  margin-right: 17px;
  transform: rotate(90deg);
}

.faq__question-button:hover {
  color: var(--soft-red);
}

.faq__desc {
  margin-left: -30px;
  padding-right: 17px;
  margin-top: 0;
  margin-bottom: 0;
  height: 0;
  overflow: hidden;
  transition: all 200ms ease-out;
}

/* Illustration */
.illustration {
  width: 100%;
  position: relative;
}

.illustration__box {
  position: absolute;
  z-index: 999;
  top: 200px;
  left: -95px;
}

.illustration__woman-desktop {
  position: absolute;
  top: 60px;
  left: -77px;
  clip-path: inset(0px 0px 0px 77px);
}

.illustration__woman-mobile {
  display: none;
}

.ant-result-icon {
  display: none !important;
}



/* Media Queries */
@media only screen and (max-width: 800px) {
  .illustration__woman-desktop {
    max-width: 120%;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    padding-top: 105px;
  }

  .component {
    max-width: 327px;
    background-image: url(../../images/bg-pattern-mobile.svg);
    background-position: top;
    flex-direction: column;
  }

  .illustration__box,
  .illustration__woman-desktop {
    display: none;
  }

  .illustration__woman-mobile {
    display: block;
    margin: 0 auto;
    position: absolute;
    top: -105px;
    left: 12px;
  }

  .faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 132px;
    padding-bottom: 48px;
  }

  .faq__question {
    max-width: 279px;
  }

  .faq__question-button {
    width: 279px;
    font-size: 13px;
    line-height: 13px;
  }
}





/*Responsive*/
@media screen and (max-width: 500px) {
  .form .column {
    flex-wrap: wrap;
  }
  .form :where(.gender-option, .gender) {
    row-gap: 15px;
  }
}