/* 
---------------------------------------------
banner
--------------------------------------------- 
*/

.main-banner {
    position: relative;
    max-height: 100%;
    overflow: hidden;
    margin-bottom: -7px;
  }
  
  #bg-video {
      min-width: 100%;
      min-height: 100vh;
      max-width: 100%;
      max-height: 100vh;
      object-fit: cover;
      z-index: -1;
  }
  
  #bg-video::-webkit-media-controls {
      display: none !important;
  }
  
  .video-overlay {
      position: absolute;
      background-color: rgba(31,39,43,0.75);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
  }
  
  /* .main-banner .caption {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
  } */

  .main-banner .caption {
    position: absolute;
    top: 45%; /* Memusatkan elemen di tengah vertikal */
    left: 50%; /* Memusatkan elemen di tengah horizontal */
    transform: translate(-50%, -50%); /* Menyesuaikan posisi untuk memastikan elemen tetap di tengah */
}

  
  .main-banner .caption h6 {
    margin-top: 0px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    letter-spacing: 1px;
  }
  
  .main-banner .caption h2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 800;
    color: #fff;
    letter-spacing: 1px;
  }
  
  .main-banner .caption h2 em {
    font-style: normal;
    color: #8A9A5B;
    font-weight: 900;
  }
  
  .main-banner .caption p {
    color: #fff;
    font-size: 18px;
    max-width: 570px;
  }
  
  .main-banner .caption .main-button-red {
    margin-top: 30px;
  }
  
  @media screen and (max-width: 767px) {
  
    .main-banner .caption h6 {
      font-weight: 500;
    }
  
    .main-banner .caption h2 {
      font-size: 36px;
    }
  
  }
  