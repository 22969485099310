@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-image-preview-root .ant-image-preview-img {
  max-width: 70% !important;
  max-height: 70%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  user-select: none;
}

/* Define scrollbar styles */
::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9c9c9c; /* Change color on hover */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #b9b8b8; /* Set the color of the scrollbar handle */
  border-radius: 6px; /* Add rounded corners to the handle */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set the color of the scrollbar track */
}

a {
  text-decoration: none !important;
  color: #3cf;
}

a:hover {
  color: #8a9a5b;
}

.btn-back {
  background-color: #a52e07;
  display: flex;
  gap: 15px;
  align-items: center;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
  width: 130px;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
}

.btn-back :hover {
  color: white !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 14px;
  line-height: 25px;
  color: #2a2a2a;
}

/* ini yang bikin map nya ancur  */

/* img {
  width: 100%;
  overflow: hidden;
} */

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: "Poppins", sans-serif;
}

::selection {
  background: #8a9a5b;
  color: #fff;
}

::-moz-selection {
  background: #8a9a5b;
  color: #fff;
}

@media (max-width: 991px) {
  html,
  body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.main-button-red a {
  font-size: 13px;
  color: #fff;
  background-color: #8a9a5b;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 22px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s;
}

.main-button-red a:hover {
  opacity: 0.9;
}

.main-button-yellow a {
  font-size: 13px;
  color: #fff;
  background-color: #8a9a5b;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 22px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s;
}

.main-button-yellow a:hover {
  opacity: 0.9;
}

.section-heading h2 {
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.15);
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262525;
}

/*
---------------------------------------------
services
---------------------------------------------
*/

.services {
  margin-top: -135px;
  position: absolute;
  width: 100%;
}

.ant-float-btn-primary {
  background-color: #2c4c3b !important;
}

.ant-float-btn-primary .ant-float-btn-body {
  background-color: #2c4c3b !important;
  transition: background-color 0.2s;
  width: 50px !important;
  height: 40px !important;
}

.services .item {
  background-color: #2c4c3b;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  padding: 40px;
  height: 16.5rem;
}

.services .item .icon {
  max-width: 60px;
  margin: 0 auto;
}

.services .item h4 {
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}

.services .item p {
  color: #fff;
  font-size: 13px;
}

.services .owl-nav {
  display: inline-block !important;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-25px);
}

.services .owl-nav .owl-prev {
  margin-right: 10px;
  outline: none;
  position: absolute;
  left: -80px;
}

.services .owl-nav .owl-prev span,
.services .owl-nav .owl-next span {
  opacity: 0;
}

.services .owl-nav .owl-prev:before {
  display: none;
  font-family: "FontAwesome";
  color: #1e1e1e;
  font-size: 25px;
  font-weight: 700;
  content: "\f104";
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
}

.services .owl-nav .owl-prev {
  opacity: 1;
  transition: all 0.5s;
}

.services .owl-nav .owl-prev:hover {
  opacity: 0.9;
}

.services .owl-nav .owl-next {
  opacity: 1;
  transition: all 0.5s;
}

.services .owl-nav .owl-next:hover {
  opacity: 0.9;
}

.services .owl-nav .owl-next {
  margin-left: 10px;
  outline: none;
  position: absolute;
  right: -85px;
}

.services .owl-nav .owl-next:before {
  display: none;
  content: "";
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50" height="50" fill="%231e1e1e"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg>')
    center center no-repeat;
  background-size: cover; /* Sesuaikan dengan kebutuhan Anda */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center; /* Menengahkan ikon */
}

/*
---------------------------------------------
upcoming meetings
---------------------------------------------
*/

section.upcoming-meetings {
  /* background-image: url(../images/meetings-bg.JPG); */
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 230px;
  padding-bottom: 110px;
}

section.upcoming-meetings .section-heading {
  text-align: center;
}

section.upcoming-meetings .categories {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
  margin-right: 45px;
}

section.upcoming-meetings .categories h4 {
  font-size: 18px;
  font-weight: 600;
  color: #1f272b;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

section.upcoming-meetings .categories ul li {
  display: inline-block;
  margin-bottom: 15px;
}

section.upcoming-meetings .categories ul li a {
  font-size: 15px;
  color: #1f272b;
  font-weight: 500;
  transition: all 0.3s;
}

section.upcoming-meetings .categories ul li a:hover {
  color: #8a9a5b;
}

section.upcoming-meetings .categories .main-button-red {
  border-top: 1px solid #eee;
  padding-top: 30px;
  margin-top: 15px;
}

section.upcoming-meetings .categories .main-button-red a {
  width: 100%;
  text-align: center;
}

.meeting-item {
  margin-bottom: 30px;
}

.meeting-item .thumb {
  position: relative;
}

.meeting-item .thumb img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.meeting-item .thumb .price {
  position: absolute;
  left: 20px;
  top: 20px;
}

.meeting-item .thumb .price span {
  font-size: 16px;
  color: #1f272b;
  font-weight: 600;
  background-color: rgba(250, 250, 250, 0.9);
  padding: 7px 12px;
  border-radius: 10px;
}

.meeting-item .down-content {
  background-color: #fff;
  padding: 30px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 2px 8px 9px -6px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 2px 8px 9px -6px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 2px 8px 9px -6px rgba(0, 0, 0, 0.21);
}

.meeting-item .down-content .date {
  float: left;
  text-align: center;
  display: inline-block;
  margin-right: 20px;
}

.meeting-item .down-content .date h6 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #8a9a5b;
}

.meeting-item .down-content .date span {
  display: block;
  color: #1f272b;
  font-size: 22px;
  margin-top: 7px;
}

.meeting-item .down-content h4 {
  font-size: 18px;
  color: #1f272b;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
}

.meeting-item .down-content p {
  margin-left: 50px;
  color: #1f272b;
  font-size: 14px;
}

.nana {
  border-radius: 10px !important;
}

/*
---------------------------------------------
apply now
---------------------------------------------
*/

section.apply-now {
  background-image: url(../images/apply-bg.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 0px;
}

section.apply-now .item {
  background-color: rgba(250, 250, 250, 0.15);
  padding: 40px;
  margin-bottom: 30px;
}

section.apply-now .item h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

section.apply-now .item p {
  color: #fff;
  margin-bottom: 20px;
}

.accordions {
  border-radius: 20px;
  padding: 30px;
  background-color: #fff;
  margin-left: 45px;
}
.accordions .accordion {
  border-bottom: 1px solid #eee;
}
.accordions .last-accordion {
  border-bottom: none;
}
.accordion-head {
  /* padding: 20px;   */
  font-size: 18px;
  font-weight: 700;
  color: #1f272b;
  cursor: pointer;
  transition: color 200ms ease-in-out;
  border-bottom: 1px solid #fff;
}
@media screen and (min-width: 768px) {
  .accordion-head {
    padding: 1rem;
    font-size: 1.2rem;
  }
}
.accordion-head .icon {
  float: right;
  transition: transform 200ms ease-in-out;
}
.accordion-head.open {
  color: #8a9a5b;
  border-bottom: none;
}

.accordion-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease-in-out;
}
.accordion-body > .content {
  padding: 0px;
  padding-top: 0;
}

.accordion-body.open {
  max-height: 500px; /* Adjust the max-height based on your content */
}

.accordion-body.closed {
  max-height: 0px; /* Adjust the max-height based on your content */
}

.content.open {
  display: block;
}

.content.closed {
  visibility: hidden;
}

/* .closed {
  display: none;
  overflow: hidden;
  transition: height 300ms ease-in-out;
} */

/* 
---------------------------------------------
courses
--------------------------------------------- 
*/

section.our-courses {
  /* background-image: url(../images/meetings-bg.JPG); */
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 140px;
  padding-bottom: 130px;
}

.our-courses .item .down-content {
  background-color: #fff;
}

.our-courses .item .down-content h4 {
  padding: 25px;
  font-size: 18px;
  color: #1f272b;
  text-align: center;
  border-bottom: 1px solid #eee;
}

.our-courses .item .down-content .info {
  padding: 25px;
}

.our-courses .item .down-content .info ul li {
  display: inline-block;
  margin-right: 1px;
}

.our-courses .item .down-content .info ul li i {
  color: #8a9a5b;
  font-size: 14px;
}

.our-courses .item .down-content .info span {
  color: #8a9a5b;
  font-size: 15px;
  font-weight: 600;
  text-align: right;
  display: inline-block;
  width: 100%;
}

.our-courses .owl-nav {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-45px);
}

.our-courses .owl-dots {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 40px;
}

.our-courses .owl-dots .owl-dot {
  transition: all 0.5s;
  width: 7px;
  height: 7px;
  background-color: #95a46b;
  margin: 0px 5px;
  border-radius: 50%;
  outline: none;
}

.our-courses .owl-dots .active {
  width: 24px;
  height: 8px;
  border-radius: 4px;
}

.our-courses .owl-nav .owl-prev {
  margin-right: 10px;
  outline: none;
  position: absolute;
  left: -80px;
}

.our-courses .owl-nav .owl-prev span,
.our-courses .owl-nav .owl-next span {
  opacity: 0;
}

.our-courses .owl-nav .owl-prev:before {
  display: inline-block;
  font-family: "FontAwesome";
  color: #1e1e1e;
  font-size: 25px;
  font-weight: 700;
  content: "\f104";
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
}

.our-courses .owl-nav .owl-prev {
  opacity: 1;
  transition: all 0.5s;
}

.our-courses .owl-nav .owl-prev:hover {
  opacity: 0.9;
}

.our-courses .owl-nav .owl-next {
  opacity: 1;
  transition: all 0.5s;
}

.our-courses .owl-nav .owl-next:hover {
  opacity: 0.9;
}

.our-courses .owl-nav .owl-next {
  margin-left: 10px;
  outline: none;
  position: absolute;
  right: -85px;
}

.our-courses .owl-nav .owl-next:before {
  display: inline-block;
  font-family: "FontAwesome";
  color: #1e1e1e;
  font-size: 25px;
  font-weight: 700;
  content: "\f105";
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
}

/*
---------------------------------------------
our facts
---------------------------------------------
*/

section.our-facts {
  background-image: url(../images/facts-bg.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 0px 125px 0px;
}

section.our-facts h2 {
  font-size: 38px;
  color: #fff;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 50px;
}

.count-area-content {
  text-align: center;
  background-color: rgba(250, 250, 250, 0.15);
  border-radius: 20px;
  padding: 25px 30px 35px 30px;
  margin: 15px 0px;
}

.percentage .count-digit:after {
  content: "%";
  margin-left: 3px;
}

.count-digit {
  margin: 5px 0px;
  color: #8a9a5b;
  font-weight: 700;
  font-size: 36px;
}
.count-title {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.5px;
}

.new-students {
  margin-top: 45px;
}

section.our-facts .video {
  text-align: center;
  margin-left: 70px;
  background-image: url(../images/video-item-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 20px;
}

section.our-facts .video img {
  padding: 170px 0px;
  max-width: 56px;
}

/* 
---------------------------------------------
contact us
--------------------------------------------- 
*/

section.contact-us {
  /* background-image: url(../images/meetings-bg.JPG); */
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px 0px 0px;
}

section.contact-us #contact {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
}

section.contact-us #contact h2 {
  text-transform: uppercase;
  color: #1f272b;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
}

section.contact-us #contact input {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background-color: #f7f7f7;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 13px;
  font-weight: 500;
  color: #7a7a7a;
  padding: 0px 15px;
  margin-bottom: 30px;
}

section.contact-us #contact textarea {
  width: 100%;
  min-height: 140px;
  max-height: 180px;
  border-radius: 20px;
  background-color: #f7f7f7;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 13px;
  font-weight: 500;
  color: #7a7a7a;
  padding: 15px;
  margin-bottom: 30px;
}

section.contact-us #contact button {
  font-size: 13px;
  color: #fff;
  background-color: #8a9a5b;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 22px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s;
  border: none;
  outline: none;
}

section.contact-us #contact button:hover {
  opacity: 0.9;
}

section.contact-us .right-info {
  background-color: #8a9a5b;
  border-radius: 20px;
  padding: 40px;
}

section.contact-us .right-info ul li {
  display: inline-block;
  border-bottom: 1px solid rgba(250, 250, 250, 0.15);
  margin-bottom: 30px;
  padding-bottom: 30px;
}

section.contact-us .right-info ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

section.contact-us .right-info ul li h6 {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

section.contact-us .right-info ul li span {
  display: block;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}

.footer {
  text-align: left;
  margin-top: 140px;
  border-top: 1px solid rgba(58, 57, 57, 0.15);
  padding: 50px 0px;
}
.footer p {
  font-size: 14px;
  color: #3f3e3e;
}

.footer p a {
  color: #8a9a5b;
}

/*
---------------------------------------------
heading page
---------------------------------------------
*/

section.heading-page {
  background-image: url(../images/heading-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 230px;
  padding-bottom: 110px;
  text-align: center;
}

section.heading-page h6 {
  margin-top: 0px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
}

section.heading-page h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
}

/*
---------------------------------------------
upcoming meetings page
---------------------------------------------
*/

section.meetings-page {
  /* background-image: url(../images/meetings-page-bg.jpg); */
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 140px;
  padding-bottom: 0px;
}

section.meetings-page .filters {
  text-align: center;
  margin-bottom: 60px;
}

section.meetings-page .filters li {
  font-size: 13px;
  color: #8a9a5b;
  background-color: #fff;
  padding: 11px 30px;
  display: inline-block;
  border-radius: 22px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s;
  cursor: pointer;
  margin: 0px 3px;
}

section.meetings-page .filters ul li.active,
section.meetings-page .filters ul li:hover {
  background-color: #8a9a5b;
  color: #fff;
}

section.meetings-page .pagination {
  text-align: center;
  width: 100%;
  margin-top: 30px;
  display: inline-block;
}

section.meetings-page .pagination ul li {
  display: inline-block;
}

section.meetings-page .pagination ul li a {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 10px;
  color: #1f272b;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  transition: all 0.3s;
}

section.meetings-page .main-button-red {
  text-align: center;
}

section.meetings-page .main-button-red a {
  padding: 12px 60px;
  text-align: center;
  margin-top: 30px;
}

section.meetings-page .pagination ul li.active a,
section.meetings-page .pagination ul li a:hover {
  background-color: #8a9a5b;
  color: #fff;
}

.meeting-single-item .thumb {
  position: relative;
}

.meeting-single-item .thumb img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.meeting-single-item .thumb .price {
  position: absolute;
  left: 20px;
  top: 20px;
}

.meeting-single-item .thumb .price span {
  font-size: 16px;
  color: #1f272b;
  font-weight: 600;
  background-color: rgba(250, 250, 250, 0.9);
  padding: 7px 12px;
  border-radius: 10px;
}

.meeting-single-item .down-content {
  background-color: #fff;
  padding: 40px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.meeting-single-item .thumb .date {
  position: absolute;
  background-color: rgba(250, 250, 250, 0.9);
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 15px 0px;
  border-radius: 10px;
  right: 20px;
  top: 20px;
}

.meeting-single-item .thumb .date h6 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #8a9a5b;
}

.meeting-single-item .thumb .date span {
  display: block;
  color: #1f272b;
  font-size: 22px;
  margin-top: 7px;
}

.meeting-single-item .down-content h4 {
  font-size: 22px;
  color: #1f272b;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
}

.meeting-single-item .down-content h5 {
  font-size: 18px;
  color: #1f272b;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 15px;
}

.meeting-single-item .down-content p {
  color: #1f272b;
  font-size: 14px;
}

.meeting-single-item .down-content p.description {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #eee;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #eee;
}

.meeting-single-item .down-content .share {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #eee;
}

.meeting-single-item .down-content .share h5 {
  float: left;
  margin-right: 10px;
  margin-bottom: 0px;
}

.meeting-single-item .down-content .share ul li {
  display: inline;
}

.meeting-single-item .down-content .share ul li a {
  font-size: 14px;
  color: #1f272b;
  transition: all 0.3s;
}

.meeting-single-item .down-content .share ul li a:hover {
  color: #8a9a5b;
}

/* Meeting item column */
.templatemo-item-col {
  width: 31%;
}

@media (max-width: 992px) {
  .templatemo-item-col {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .templatemo-item-col {
    width: 100%;
  }
}

/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/

@media (max-width: 1300px) {
  .services .owl-nav .owl-next {
    right: -30px;
  }
  .services .owl-nav .owl-prev {
    left: -25px;
  }
  .our-courses .owl-nav .owl-next {
    right: -30px;
  }
  .our-courses .owl-nav .owl-prev {
    left: -25px;
  }
}

@media (max-width: 1200px) {
  .services .owl-nav .owl-next {
    right: -70px;
  }
  .services .owl-nav .owl-prev {
    left: -65px;
  }
  .our-courses .owl-nav .owl-next {
    right: -70px;
  }
  .our-courses .owl-nav .owl-prev {
    left: -65px;
  }
}

@media (max-width: 1085px) {
  .services .owl-nav .owl-next {
    right: -30px;
  }
  .services .owl-nav .owl-prev {
    left: -25px;
  }
  .our-courses .owl-nav .owl-next {
    right: -30px;
  }
  .our-courses .owl-nav .owl-prev {
    left: -25px;
  }
}

@media (max-width: 1005px) {
  .services .owl-nav .owl-next {
    display: none;
  }
  .services .owl-nav .owl-prev {
    display: none;
  }
  .our-courses .owl-nav .owl-next {
    display: none;
  }
  .our-courses .owl-nav .owl-prev {
    display: none;
  }
}

@media (max-width: 992px) {
  .main-banner .caption {
    top: 60%;
  }

  .main-banner .caption h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 22px;
  }

  .main-banner .caption .main-button-red {
    margin-top: 15px;
  }

  .services {
    margin-top: 60px;
  }

  section.upcoming-meetings {
    padding-top: 400px;
  }

  section.upcoming-meetings .categories {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .accordions {
    margin-left: 0px;
  }

  .new-students {
    margin-top: 15px;
  }

  section.our-facts .video {
    margin-left: 0px;
    margin-top: 15px;
  }

  section.contact-us #contact {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .sub-header .left-content p {
    display: none;
  }

  .sub-header .right-icons {
    text-align: center;
  }

  .main-nav .nav .sub-menu {
    display: none;
  }

  .header-area .main-nav .nav li ul.sub-menu li a {
    color: #1f272b;
  }
}
