
.ant-input-group-addon:last-child .ant-input-search-button {
  background:  #2C4C3B  !important; 
}

.ant-input-search .ant-input:hover, :where(.css-dev-only-do-not-override-1qhpsh8).ant-input-search .ant-input:focus {
  border-color: #859A5B !important;
}

.ant-input-outlined:hover {
  border-color: #859A5B !important;
  background-color: #ffffff;
}

  .card {
    width: 240px;
    display: inline-block;
    margin: 0.5rem;
    border-radius: 10px;
    box-shadow: 2px 3px 13px -5px rgba(0,0,0,0.74);
    -webkit-box-shadow: 2px 3px 13px -5px rgba(0,0,0,0.74);
    -moz-box-shadow: 2px 3px 13px -5px rgba(0,0,0,0.74);
    transition: all 0.2s ease;
    background: #fff;
    position: relative;
    overflow: hidden;
  }
  .card:hover, .card.hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.3), 0 0 1px 0 rgba(0, 0, 0, 0.25);
  }
  .card:hover .card-content, .card.hover .card-content {
    box-shadow: inset 0 3px 0 0 #859A5B;
    border-color: #859A5B;
  }
  .card:hover .card-img .overlay, .card.hover .card-img .overlay {
    background-color: rgba(25, 29, 38, 0.85);
    transition: opacity 0.2s ease;
    opacity: 1;
  }
  .card-img {
    position: relative;
    height: 270px;
    width: 100%;
    background-color: #fff;
    transition: opacity 0.2s ease;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .card-img .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
  }
  .card-img .overlay .overlay-content {
    line-height: 380px;
    width: 100%;
    text-align: center;
    color: #fff;
  }
  .card-img .overlay .overlay-content button {
    color: #fff;
    padding: 0 2rem;
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.4);
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  .card-img .overlay .overlay-content a:hover, .card-img .overlay .overlay-content button.hover {
    background: #738b42  !important;
    border-color: #859A5B  !important;
    color:#fff !important ;
  }
  .card-content {
    width: 100%;
    min-height: 80px;
    background-color: #fff;
    border-top: 1px solid #E9E9EB;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 1rem 2rem;
    transition: all 0.2s ease;
  }
  .card-content a {
    text-decoration: none;
    color: #202927;
  }
  .card-content h2, .card-content a h2 {
    font-size: 1rem;
    font-weight: 500;
  }
  .card-content p, .card-content a p {
    font-size: 0.8rem;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(32, 41, 28, 0.8);
  }


  .fh5---banner---container {
    display: none !important;
    z-index: 1 !important;
  }

  #aw0 {
    display: none !important;
  }

  .adsbygoogle {
    display: none !important;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
    background-color: #2C4C3B   !important;
    border-radius: 6px !important;
    width: 135px !important;
    padding: 5px 15px !important;
    text-shadow: 0 0 0.25px currentcolor;
    /* text-align: left !important; */
}

.ant-tabs-nav-wrap {
  /* background-color: #FAFAFA  !important; */
  /* padding: 10px; */
}

.ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #2C4C3B   !important;
  pointer-events: none;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #2C4C3B  !important;
}