.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #859A5B;
    color: #fff;
    /* max-height: 50px; */
    z-index: 2;
  }
  
  .how-it-works.row .col-2 {
    align-self: stretch;
  }
  .how-it-works.row .col-2::after {
    content: "";
    position: absolute;
    border-left: 3px solid #859A5B;
    z-index: 1;
    display: none;
  }
  .how-it-works.row .col-2.bottom::after {
    height: 50%;
    left: 50%;
    top: 50%;
  }
  .how-it-works.row .col-2.full::after {
    display:none;
    height: 100%;
    left: calc(50% - 3px);
  }
  .how-it-works.row .col-2.top::after {
    height: 50%;
    display:none;
    left: 50%;
    top: 0;
  }
  
  
  .timeline div {
    padding: 0;
    height: 40px;
  }
  .timeline hr {
    border-top: 3px solid #859A5B;
    margin: 0;
    top: 17px;
    position: relative;
  }
  .timeline .col-2 {
    display: flex;
    overflow: hidden;
  }
  .timeline .corner {
    border: 3px solid #859A5B;
    width: 100%;
    position: relative;
    border-radius: 15px;
  }
  .timeline .top-right {
    left: 50%;
    top: -50%;
  }
  .timeline .left-bottom {
    left: -50%;
    top: calc(50% - 3px);
  }
  .timeline .top-left {
    left: -50%;
    top: -50%;
  }
  .timeline .right-bottom {
    left: 50%;
    top: calc(50% - 3px);
  }
  

.struktur-wrapper img {
    border-radius: 20px;
    border: none;
}

.struktur-wrapper {
    width: 100%;
}

.outer-wrapper {
    display:flex ;
    justify-content: center;
    border-radius: 3px;
}



/* @media (max-width: 991px) {
    .outer-wrapper {
        display:flex ;
        justify-content: center;
        border-radius: 3px;
        border: 2px solid gray;
        padding: 2rem;
    }
  }

  @media (max-width: 500px) {
    .outer-wrapper {
        display:flex ;
        justify-content: center;
        border-radius: 3px;
        border:none;
        padding: 2rem;
    }
  } */
  
  /* Stylesheet */


@media screen and (max-width: 768px) {
  .responsive-drawer {
    width: 70vw;
  }
}
